/**
 * Site container
 */

.site-container {
    text-align: center;
    
    .page-content {
        text-align: left;
    }
    
    .site-header {
        text-align: center;
	margin-bottom: $spacing-unit;
	border-bottom: 1px solid black;
    }
}


/**
 * Site header
 */
.site-header {
    padding-top: $spacing-unit;
    min-height: 56px;

    // Positioning context for the mobile navigation icon
    vertical-align: top;
    
    @include media-query($on-palm) {
    }
}

.site-title-links {
    margin: 0 auto;
    width: 80%;
    
    .title {
        font-size: 40px;
        line-height: 56px;
        text-align: center;
	font-weight: 600;
        
        color: black;
    }
    
    &,
    &:visited {
        color: $grey-color-dark;
    }
    
    @include media-query($on-palm) {
        text-align: center;
    }
}

img.headerImage {
    -webkit-clip-path: circle();
}

.site-nav {
    line-height: 36px;

    .menu-icon {
        display: none;
    }

    .page-link {
	font-weight: 400;
        color: $text-color;
        line-height: $base-line-height;
	margin: 10px;
    }

    @include media-query($on-palm) {
    }
}

.contact-link {
    line-height: $base-line-height;
}

div.spacer {
    padding-top: $spacing-unit / 4;
}

/**
 * Site footer
 */
.site-footer {
    border-top: 1px solid $grey-color-light;
    padding: $spacing-unit 0;
}

.footer-heading {
    font-size: 18px;
    margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
    list-style: none;
    margin-left: 0;
}

.footer-col-wrapper {
    font-size: 15px;
    color: $grey-color;
    margin-left: -$spacing-unit / 2;
    @extend %clearfix;
}

.footer-col {
    float: left;
    line-height: 1.5px;
    margin-bottom: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;
}

.footer-col-1 {
    width: -webkit-calc(35% - (#{$spacing-unit} / 2));
    width:         calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
    width: -webkit-calc(20% - (#{$spacing-unit} / 2));
    width:         calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
    width: -webkit-calc(45% - (#{$spacing-unit} / 2));
    width:         calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
    .footer-col-1,
    .footer-col-2 {
        width: -webkit-calc(50% - (#{$spacing-unit} / 2));
        width:         calc(50% - (#{$spacing-unit} / 2));
    }

    .footer-col-3 {
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}

@include media-query($on-palm) {
    .footer-col {
        float: none;
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}



/**
 * Page content
 */
.page-content {
    padding: $spacing-unit 0;
    display: inline-block;
}

.page-heading {
    font-size: 20px;
}

.home-post {
    padding-top: 65px - $spacing-unit;

    padding-left: $spacing-unit;
    margin-left: 0;
    
    @include media-query($on-palm) {
        padding-top: 0px;
        padding-left: $spacing-unit;
    }
}

.post-link-wrapper {
    padding-bottom: $spacing-unit;
}

.post-meta {
    font-size: $small-font-size;
    color: $grey-color;
}

.post-link {
    display: block;
    font-size: 24px;
}



/**
 * Posts
 */

.post {
    padding-top: 65px - $spacing-unit;
    padding-left: $spacing-unit;

    @include media-query($on-palm) {
        padding-left: $spacing-unit/2;
        margin-top: - $spacing-unit * 2;
    }
}

.post-header {
    margin-bottom: $spacing-unit;
}

.post-title {
    font-size: 36px;
    letter-spacing: -1px;
    line-height: 1;

    @include media-query($on-laptop) {
        font-size: 36px;
    }
}

.post-content {
    margin-bottom: $spacing-unit;

    div.publications {
        ul {
            list-style: none;
        }
        li {
            padding-left: 22px;
            text-indent: -22px;
        }
    }
    
}
